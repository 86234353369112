<template>
  <div>
    <vPreloader />
  </div>
</template>

<script>
import vPreloader from '@/components/v-preloader'
export default {
  name: 'EmptyPage',
  components: {
    vPreloader,
  },
  mounted() {
    this.$router.push('/')
  },
}
</script>
<style lang="scss"></style>
